import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StarsService {

    constructor(private http: HttpClient) { }

    getPID(uscid: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/pid?id=' + uscid, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }

    getDegrees(id: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/stars/degrees?id=' + id, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }

    getReport(id: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/stars/report?id=' + id, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }

    decryptID(data: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/util/decryptID?data=' + data.replace(/\+/gi, '%2B'), { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }

    decryptData(cipher: any, nonce: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/util/decryptData?cipher=' + cipher.replace(/\+/gi, '%2B') + '&nonce=' + nonce.replace(/\+/gi, '%2B'), { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }

    encryptData(uscid: any, pid: any, employeeId: any): Observable<any> {
        return this.http.get<any[]>(environment.backendServiceUrl + '/api/util/encryptData?uscid=' + uscid + '&pid=' + pid + '&employeeId=' + employeeId, { headers: new HttpHeaders({ 'Authorization': 'Bearer ' + environment.token }) });
    }
}